import { intentBlockingMiddleware } from '../middleware/intentBlocking';
import { nativeNavigationHelper } from '../middleware/nativeNavigationHelper';
import { createRootReducer } from '../reducers';
import { addRunFunction } from '../runnable';
import { rootSaga } from '../sagas';
import { addSubscribeSelector } from '../subscribeSelector';
import type { SagaContext } from '../types';
import { composeWithDevTools } from '@redux-devtools/extension';
import type { History } from 'history';
import { createBrowserHistory, createMemoryHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { createReduxHistoryContext } from 'redux-first-history';
import createSagaMiddleware from 'redux-saga';
import { TypedContainerModule, type TypedContainer } from 'src/features/ioc/TypedContainer';
import { isNative } from 'src/features/native';
import { type ReactBindings, type IsomorphicBindings, type StaticBindings } from 'src/ioc/types';

export const localStoreModule = new TypedContainerModule<StaticBindings & IsomorphicBindings>((bind) => {
    type Container = TypedContainer<StaticBindings & IsomorphicBindings>;

    let history: History;
    if (isNative) {
        history = createMemoryHistory();
    } else {
        history = createBrowserHistory();
    }

    const { routerReducer, routerMiddleware, createReduxHistory } = createReduxHistoryContext({
        history,
        showHistoryAction: true,
    });

    bind('Store')
        .toDynamicValue((ctx) => {
            const container = ctx.container as unknown as TypedContainer<ReactBindings>;
            const context: SagaContext = {
                configService: container.get('ConfigService'),
                electronService: container.isBound('ElectronService') ? container.get('ElectronService') : undefined,
                loginScreenService: container.get('LoginScreenService'),
                oAuth2Client: container.get('OAuth2Service'),
                overlaysServiceProvider: () => {
                    if (container.isBound('OverlaysService')) {
                        return container.get('OverlaysService');
                    }
                },
                launchDarklyClient: container.get('LaunchDarklyClient'),
                container,
            };

            const sagaMiddleware = createSagaMiddleware({ context });

            const middlewares = [nativeNavigationHelper, intentBlockingMiddleware, routerMiddleware, sagaMiddleware];
            const middlewareEnhancer = applyMiddleware(...middlewares);

            const enhancers = compose(
                addSubscribeSelector,
                addRunFunction(() => {
                    sagaMiddleware.run(rootSaga);
                }),
                composeWithDevTools(middlewareEnhancer),
            );

            // Casting here since the return type of `createStore` doesn't reflect the enhancers we're adding
            return createStore(createRootReducer({ routerReducer }), {}, enhancers) as IsomorphicBindings['Store'];
        })
        .inSingletonScope();

    bind('History')
        .toDynamicValue((ctx) => {
            const store = (ctx.container as unknown as Container).get('Store');
            return createReduxHistory(store);
        })
        .inSingletonScope();
});
