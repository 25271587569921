import type { UploadableLocateProvider } from '@thinkalpha/common/contracts/locates/inventory.js';
import type { LocateOrder } from '@thinkalpha/common/contracts/locates/orders.js';
import type { LocateAccount } from '@thinkalpha/common/contracts/locates/platforms.js';
import type { LocateProvider } from '@thinkalpha/common/contracts/locates/providers.js';
import localforage from 'localforage';
import type { Action } from 'redux';
import { all, call, delay, put, race, select, take } from 'redux-saga/effects';
import type { LocatePreferences } from 'src/contracts/user-preference';
import { container } from 'src/ioc/StaticContainer';
import type { LocatesClient } from 'src/lib/locates';
import { getLocatesClient } from 'src/routes/widgets/LocatesWidget/hooks/useLocatesClient';
import { setLocatePreferences } from 'src/store/actions/locates/locatePreferences';
import type { SetLocatesAccountsAction } from 'src/store/actions/locates/locates';
import {
    setLocatesAccounts,
    setLocatesOrders,
    setLocatesProviders,
    setUploadableLocatesProviders,
} from 'src/store/actions/locates/locates';
import { makeLocatesEmissionAction } from 'src/store/actions/locates/locatesSocket';
import { getLocatesAccounts as selectLocatesAccounts } from 'src/store/selectors/locates/locates';
import { LOCATE_PREFERENCES_KEY, defaultLocatePreferences } from 'src/store/types/locates';

const log = container.get('Logger').getSubLogger({ name: 'locates-bootstrap-sagas' });

const BOOTSTRAP_TIMEOUT_MS = 10000;
const BOOTSTRAP_RETRY_MAX = 10;

type BOOTSTRAP_RACE<T> = { timedOut?: true; response?: T };

/**
 * A function that generates a self-contained loop to attempt to get data from the client
 * @param target a string representing what the loop should be getting, such as 'orders' or 'providers'
 * @param getFunction the client function that will be called to get the expected data
 * @param setFunction the action that will be called to set the data in the store
 * @returns true if the data was successfully retrieved, null if it failed
 */
function* getFromLocatesLoop<T>(target: string, getFunction: () => Promise<T>, setFunction: (value: T) => Action) {
    try {
        // will be used to count the number of times the loop has run
        let x = 0;
        // will be used to store the response from the client
        let clientResponse: T | null = null;
        while (x < BOOTSTRAP_RETRY_MAX) {
            // run a race between BOOTSTRAP_TIMEOUT_MS and the client function
            try {
                const { timedOut, response }: BOOTSTRAP_RACE<T> = yield race({
                    timedOut: delay(BOOTSTRAP_TIMEOUT_MS),
                    response: call(getFunction),
                });

                if (timedOut || !response) {
                    // if we didn't get a response or timed out, increment the loop counter and try again
                    x++;
                    continue;
                } else {
                    // if we did, set the response and stop the loop
                    clientResponse = response;
                    break;
                }
            } catch (e) {
                // if the client function threw an error, increment the loop counter and try again
                x++;
                continue;
            }
        }

        if (clientResponse !== null) {
            // if we got a response, set the data in the store and return true
            yield put(setFunction(clientResponse));
            return true;
        } else {
            // otherwise the loop ran BOOTSTRAP_RETRY_MAX times, so return null
            return null;
        }
    } catch (e) {
        if (e instanceof Error) {
            log.error({ message: `Bootstrapping locates failed for ${target} `, error: e });
        } else {
            log.fatal({ message: 'Unknown exception', details: e });
        }

        return null;
    }
}

function* getLocatesProviders(client: LocatesClient) {
    try {
        const wasLoopSuccessful: true | null = yield getFromLocatesLoop<LocateProvider[]>(
            'providers',
            client.getLocatesProviders,
            setLocatesProviders,
        );
        if (!wasLoopSuccessful) throw new Error('Failed to bootstrap locates providers');
    } catch (e) {
        if (e instanceof Error) {
            log.error({ message: 'Bootstrapping locates providers failed', error: e });
        } else {
            log.fatal({ message: 'Unknown exception', details: e });
        }

        return null;
    }
}

function* getUploadableLocatesProviders(client: LocatesClient) {
    try {
        const wasLoopSuccessful: true | null = yield getFromLocatesLoop<UploadableLocateProvider[]>(
            'uploadable providers',
            client.getUploadableLocatesProviders,
            setUploadableLocatesProviders,
        );
        if (!wasLoopSuccessful) throw new Error('Failed to bootstrap uploadable locates providers');
    } catch (e) {
        if (e instanceof Error) {
            log.error({ message: 'Bootstrapping locates uploadable providers failed', error: e });
        } else {
            log.fatal({ message: 'Unknown exception', details: e });
        }

        return null;
    }
}

function* getLocatesAccounts(client: LocatesClient) {
    try {
        const wasLoopSuccessful: true | null = yield getFromLocatesLoop<LocateAccount[]>(
            'accounts',
            client.getLocatesAccounts,
            setLocatesAccounts,
        );
        if (!wasLoopSuccessful) throw new Error('Failed to bootstrap locates accounts');
    } catch (e) {
        if (e instanceof Error) {
            log.error({ message: 'Bootstrapping locates accounts failed', error: e });
        } else {
            log.fatal({ message: 'Unknown exception', details: e });
        }

        return null;
    }
}

function* getLocatesPreferences() {
    try {
        const localStorageLocatesPreferences: LocatePreferences | undefined =
            yield localforage.getItem(LOCATE_PREFERENCES_KEY);
        const locatesPreferences: LocatePreferences = localStorageLocatesPreferences ?? defaultLocatePreferences;
        yield put(setLocatePreferences(locatesPreferences));
    } catch (e) {
        if (e instanceof Error) {
            log.error({ message: 'Bootstrapping locates preferences failed', error: e });
        } else {
            log.fatal({ message: 'Unknown exception', details: e });
        }

        return null;
    }
}

function* getLocatesOrders(client: LocatesClient) {
    try {
        const wasLoopSuccessful: true | null = yield getFromLocatesLoop<LocateOrder[]>(
            'orders',
            client.getLocatesOrders,
            setLocatesOrders,
        );
        if (!wasLoopSuccessful) throw new Error('Failed to bootstrap locates orders');
    } catch (e) {
        if (e instanceof Error) {
            log.error({ message: 'Bootstrapping locates providers failed', error: e });
        } else {
            log.fatal({ message: 'Unknown exception', details: e });
        }

        return null;
    }
}

function* emitRoomJoin() {
    try {
        yield put(
            makeLocatesEmissionAction({
                type: 'room.leave',
                payload: [],
            }),
        );

        let accounts: LocateAccount[] = yield select(selectLocatesAccounts);

        if (accounts.length === 0) {
            const { timedOut, gotAccountsAction }: { timedOut?: true; gotAccountsAction?: SetLocatesAccountsAction } =
                yield race({
                    timedOut: delay(BOOTSTRAP_TIMEOUT_MS * 2),
                    gotAccountsAction: take('locates::setAccounts'),
                });

            if (timedOut || !gotAccountsAction || gotAccountsAction.accounts.length === 0) {
                log.error({ message: 'Failed to get accounts for room join' });
                return null;
            } else {
                accounts = gotAccountsAction.accounts;
            }
        }

        yield put(
            makeLocatesEmissionAction({
                type: 'room.join',
                payload: [{ type: 'account', accountId: accounts.map((acc) => acc.id) }],
            }),
        );
    } catch (e) {
        if (e instanceof Error) {
            log.error({ message: 'Bootstrapping locates room join failed', error: e });
        } else {
            log.fatal({ message: 'Unknown exception', details: e });
        }

        return null;
    }
}

export function* executeBootstrapLocatesFunctions() {
    const client: LocatesClient = yield call(getLocatesClient);

    yield all([
        getLocatesProviders(client),
        getUploadableLocatesProviders(client),
        getLocatesAccounts(client),
        getLocatesPreferences(),
        getLocatesOrders(client),
        emitRoomJoin(),
    ]);

    return true;
}
