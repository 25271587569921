import { Instant } from '@js-joda/core';
import { all, call, fork, put, select } from 'redux-saga/effects';
import { SUPERUSER_ACCESS_EXPIRATION_KEY } from 'src/api';
import { SYMBOL_CACHE_KEY } from 'src/constants/storage-keys';
import type { IfThenLineSettings } from 'src/if-then/model';
import { DEFAULT_LINE_SETTINGS_CACHE_KEY } from 'src/if-then/model';
import { localforage } from 'src/lib/serialization/localForage';
import type { RootState } from 'src/store';
import { setSuperuserAccessEnabledUntil } from 'src/store/actions/admin';
import { completeBootstrap } from 'src/store/actions/app';
import { setAccessToken, setMasqToken, setRefreshToken } from 'src/store/actions/auth';
import { setDefaultLineSettings, setSymbolCache } from 'src/store/actions/ui';
import type { SymbolCacheEntry } from 'src/store/reducers/ui';
import {
    retrieveStoredAccessToken,
    retrieveStoredMasqToken,
    retrieveStoredRefreshToken,
} from 'src/store/sagas/auth/helpers';

export function* checkInitialAuthTokens() {
    const [refreshToken, accessToken, masqToken]: [
        Awaited<ReturnType<typeof retrieveStoredRefreshToken>>,
        Awaited<ReturnType<typeof retrieveStoredAccessToken>>,
        Awaited<ReturnType<typeof retrieveStoredMasqToken>>,
    ] = yield all([call(retrieveStoredRefreshToken), call(retrieveStoredAccessToken), call(retrieveStoredMasqToken)]);
    yield all({
        refreshToken: put(setRefreshToken(refreshToken, true)),
        accessToken: put(setAccessToken(accessToken, true)),
        masqToken: put(setMasqToken(masqToken, true)),
    });
    // return { refreshToken, accessToken, masqToken };
}

function* initAdminState() {
    const superuserAccessExpiration: Instant | undefined = yield localforage.getItem(SUPERUSER_ACCESS_EXPIRATION_KEY);

    const isValidAccessTime = superuserAccessExpiration && superuserAccessExpiration.isAfter(Instant.now());

    if (isValidAccessTime) {
        yield put(setSuperuserAccessEnabledUntil(superuserAccessExpiration));
    }
}

function* initSymbolCache() {
    const symbolCache: SymbolCacheEntry[] | undefined = yield localforage.getItem(SYMBOL_CACHE_KEY);
    yield put(setSymbolCache(symbolCache || []));
}

function* setDefaultLineSettingsState() {
    const defaultLineSettings: IfThenLineSettings | undefined = yield localforage.getItem(
        DEFAULT_LINE_SETTINGS_CACHE_KEY,
    );

    if (defaultLineSettings) {
        yield put(setDefaultLineSettings(defaultLineSettings));
    }
}

function* performBootstrapTasks() {
    // Run these first in order to setup the rest of any API calls we may have

    yield call(initAdminState);
    yield call(checkInitialAuthTokens);

    // Makes detached forks; so basically, we are going to send a bunch
    // of tasks to go finish, then when they are done the whole saga will
    // finally complete. It will just hang out until the last of the tasks are
    // done, or error.
    yield fork(initSymbolCache);
    yield fork(setDefaultLineSettingsState);
}

export function* bootstrapAppSaga() {
    const isBootstrapped: boolean = yield select((state: RootState) => state.app.bootstrapped);

    if (!isBootstrapped) {
        // Call here
        yield call(performBootstrapTasks);

        yield put(completeBootstrap());
    }
}
