import type { SecurityInventory, UploadableLocateProvider } from '@thinkalpha/common/contracts/locates/inventory.js';
import type { AutoAcceptInfo, LocateOrder, NewLocateOrder } from '@thinkalpha/common/contracts/locates/orders.js';
import type { LocateAccount, LocateReceipt } from '@thinkalpha/common/contracts/locates/platforms.js';
import type { LocateProvider } from '@thinkalpha/common/contracts/locates/providers.js';
import type { WebSocketStatus } from '@thinkalpha/common/push/socket.js';
import type { SoundAlert } from 'src/store/types/locates';

export type SetLocatesAdminAction = { type: 'locates::set-admin'; isAdmin: boolean };
export const setLocatesAdmin = (isAdmin: boolean): SetLocatesAdminAction => ({
    type: 'locates::set-admin',
    isAdmin,
});

export type SetLocatesProvidersAction = { type: 'locates::setProviders'; providers: LocateProvider[] };
export const setLocatesProviders = (providers: LocateProvider[]): SetLocatesProvidersAction => ({
    type: 'locates::setProviders',
    providers,
});

export type SetUploadableLocatesProvidersAction = {
    type: 'locates::setUploadableProviders';
    uploadableProviders: UploadableLocateProvider[];
};
export const setUploadableLocatesProviders = (
    uploadableProviders: UploadableLocateProvider[],
): SetUploadableLocatesProvidersAction => ({
    type: 'locates::setUploadableProviders',
    uploadableProviders,
});

export type BootstrapLocatesAction = { type: 'locates::bootstrap' };
export const bootstrapLocates = (): BootstrapLocatesAction => ({
    type: 'locates::bootstrap',
});

export type SetIsLocatesBoostrapCompleteAction = {
    type: 'locates::setBootstrapComplete';
    isLocatesBootstrapComplete: boolean;
};
export const setIsLocatesBoostrapComplete = (
    isLocatesBootstrapComplete: boolean,
): SetIsLocatesBoostrapCompleteAction => ({
    type: 'locates::setBootstrapComplete',
    isLocatesBootstrapComplete,
});

export type SetLocatesAccountsAction = {
    type: 'locates::setAccounts';
    accounts: LocateAccount[];
};
export const setLocatesAccounts = (accounts: LocateAccount[]): SetLocatesAccountsAction => ({
    type: 'locates::setAccounts',
    accounts,
});

export type SetLocatesStatusAction = {
    type: 'locates::setStatus';
    status: WebSocketStatus;
};
export const setLocatesStatus = (status: WebSocketStatus): SetLocatesStatusAction => ({
    type: 'locates::setStatus',
    status,
});

export type SetLocatesOrdersAction = {
    type: 'locates::setOrders';
    orders: LocateOrder[];
};
export const setLocatesOrders = (orders: LocateOrder[]): SetLocatesOrdersAction => ({
    type: 'locates::setOrders',
    orders,
});

export type OnOrderUpdateAction = {
    type: 'locates::onOrderUpdate';
    orders: LocateOrder[];
    source: string; // devtool used to determine where an update came from, such as socket or a redux function
};
export const onOrderUpdate = (orders: LocateOrder[], source: string): OnOrderUpdateAction => ({
    type: 'locates::onOrderUpdate',
    orders,
    source,
});

export type OnOrderRemoveAction = {
    type: 'locates::onOrderRemove';
    removeIds: string[];
    source: string; // devtool used to determine where an update came from, such as socket or a redux function
};
export const onOrderRemove = (removeIds: string[], source: string): OnOrderRemoveAction => ({
    type: 'locates::onOrderRemove',
    removeIds,
    source,
});

export type OnStatusChangeAction = {
    type: 'locates::onStatusChange';
    status: WebSocketStatus;
};
export const onStatusChange = (status: WebSocketStatus): OnStatusChangeAction => ({
    type: 'locates::onStatusChange',
    status,
});

export type CreateLocatesOrderAction = {
    type: 'locates::newOrderRequest';
    order: Omit<NewLocateOrder, 'optimisticOrderId'>;
    autoAcceptPrice: number | undefined;
};
export const createLocatesOrder = (
    order: Omit<NewLocateOrder, 'optimisticOrderId'>,
    autoAcceptPrice?: number,
): CreateLocatesOrderAction => ({
    type: 'locates::newOrderRequest',
    order,
    autoAcceptPrice,
});

export type InjectLocateAction = {
    type: 'locates::inject';
    receipts: Omit<LocateReceipt, 'optimisticOrderId'>[];
    accountId: string;
};
export const injectLocate = (
    receipts: Omit<LocateReceipt, 'optimisticOrderId'>[],
    accountId: string,
): InjectLocateAction => ({
    type: 'locates::inject',
    receipts,
    accountId,
});

export type UploadSecurityAction = {
    type: 'locates::uploadSecurity';
    poolId: string;
    inventory: SecurityInventory[];
};
export const uploadSecurity = (poolId: string, inventory: SecurityInventory[]): UploadSecurityAction => ({
    type: 'locates::uploadSecurity',
    poolId,
    inventory,
});

export type AcceptLocateOrderAction = {
    type: 'locates::accept';
    orderId: string;
};
export const acceptLocateOrder = (orderId: string): AcceptLocateOrderAction => ({
    type: 'locates::accept',
    orderId,
});

export type RejectLocateOrderAction = {
    type: 'locates::reject';
    orderId: string;
};
export const rejectLocateOrder = (orderId: string): RejectLocateOrderAction => ({
    type: 'locates::reject',
    orderId,
});

export type RejectAllLocatesOrdersAction = {
    type: 'locates::rejectAll';
    accountIds: string[];
};
export const rejectAllLocatesOrders = (accountIds: string[]): RejectAllLocatesOrdersAction => ({
    type: 'locates::rejectAll',
    accountIds,
});

export type CancelLocateOrderAction = {
    type: 'locates::cancel';
    orderId: string;
};
export const cancelLocateOrder = (orderId: string): CancelLocateOrderAction => ({
    type: 'locates::cancel',
    orderId,
});

export type ChangeAutoAcceptAction = {
    type: 'locates::changeAutoAccept';
    orderId: string;
    autoAcceptInfo: AutoAcceptInfo;
};
export const changeAutoAccept = (orderId: string, autoAcceptInfo: AutoAcceptInfo): ChangeAutoAcceptAction => ({
    type: 'locates::changeAutoAccept',
    orderId,
    autoAcceptInfo,
});

export type OnUpdateSoundAlertsAction = {
    type: 'locates::onUpdateSoundAlerts';
    enabled: boolean;
    accountIds: string[];
    source: string;
};
export const onUpdateSoundAlerts = (
    enabled: boolean,
    accountIds: string[],
    source: string,
): OnUpdateSoundAlertsAction => ({
    type: 'locates::onUpdateSoundAlerts',
    enabled,
    accountIds,
    source,
});

export type SetEnabledSoundAlerts = {
    type: 'locates::setEnabledSoundAlerts';
    enabledSoundAlerts: SoundAlert[];
};
export const setEnabledSoundAlerts = (enabledSoundAlerts: SoundAlert[]): SetEnabledSoundAlerts => ({
    type: 'locates::setEnabledSoundAlerts',
    enabledSoundAlerts,
});

export type SetShouldExplodeLocatesAction = {
    type: 'locates::setShouldExplodeLocates';
    shouldExplodeLocates: boolean;
};
export const SetShouldExplodeLocates = (shouldExplodeLocates: boolean): SetShouldExplodeLocatesAction => ({
    type: 'locates::setShouldExplodeLocates',
    shouldExplodeLocates,
});

export type ResetLocatesAction = {
    type: 'locates::reset';
};
export const resetLocates = (): ResetLocatesAction => ({
    type: 'locates::reset',
});

export type ConsiderLocatesSnackbarOrdersAction = {
    type: 'locates::considerLocatesSnackbarOrders';
    orders: LocateOrder[];
};
export const considerLocatesSnackbarOrders = (orders: LocateOrder[]): ConsiderLocatesSnackbarOrdersAction => ({
    type: 'locates::considerLocatesSnackbarOrders',
    orders,
});

export type LocatesAction =
    | SetLocatesAdminAction
    | SetLocatesProvidersAction
    | SetUploadableLocatesProvidersAction
    | SetIsLocatesBoostrapCompleteAction
    | BootstrapLocatesAction
    | SetLocatesAccountsAction
    | SetLocatesStatusAction
    | SetLocatesOrdersAction
    | OnOrderUpdateAction
    | OnStatusChangeAction
    | CreateLocatesOrderAction
    | InjectLocateAction
    | AcceptLocateOrderAction
    | RejectLocateOrderAction
    | RejectAllLocatesOrdersAction
    | CancelLocateOrderAction
    | ChangeAutoAcceptAction
    | UploadSecurityAction
    | OnUpdateSoundAlertsAction
    | SetEnabledSoundAlerts
    | SetShouldExplodeLocatesAction
    | ResetLocatesAction
    | OnOrderRemoveAction;
