import type { ContainerLayoutViewModel, ContainerViewModel, WidgetTabViewModel } from '../types';
import type { WidgetCreationInfo } from 'src/contracts/workspace';

export type AddContainerAction = {
    type: 'addContainer';
    container: ContainerViewModel;
    initialTab: WidgetTabViewModel;
    layout: ContainerLayoutViewModel;
};

export const addContainer = (
    container: ContainerViewModel,
    initialTab: WidgetTabViewModel,
    layout: ContainerLayoutViewModel,
): AddContainerAction => ({
    type: 'addContainer',
    container,
    initialTab,
    layout,
});

export type RemoveContainerAction = {
    type: 'removeContainer';
    container: ContainerViewModel;
};

export const removeContainer = (container: ContainerViewModel): RemoveContainerAction => ({
    type: 'removeContainer',
    container,
});

export type SetActiveContainerAction = {
    type: 'setActiveContainer';
    containerId: string;
};

export const setActiveContainer = (containerId: string): SetActiveContainerAction => ({
    type: 'setActiveContainer',
    containerId,
});

export type AddWidgetToDashboardAction = {
    type: 'addWidgetToDashboard';
    widget: WidgetCreationInfo;
};

export const addWidgetToDashboard = (widget: WidgetCreationInfo): AddWidgetToDashboardAction => ({
    type: 'addWidgetToDashboard',
    widget,
});

export type SetMaximizedContainerAction = {
    type: 'setMaximizedContainer';
    containerId: string | undefined;
};

export const setMaximizedContainer = (containerId: string | undefined): SetMaximizedContainerAction => ({
    type: 'setMaximizedContainer',
    containerId,
});

export type ContainerAction =
    | AddWidgetToDashboardAction
    | SetActiveContainerAction
    | AddContainerAction
    | RemoveContainerAction
    | SetMaximizedContainerAction;
