import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import type { Layout, WidgetContainer, WidgetTab } from 'src/contracts/workspace';
import type { RootState } from 'src/store';
import type { AddWidgetToDashboardAction } from 'src/store/actions/container';
import { addContainer } from 'src/store/actions/container';
import { type AddNewWidgetToContainerAction, addNewWidgetToContainer, addTab } from 'src/store/actions/tab';
import { createWidgetTabFromCreationInfo } from 'src/store/sagas/widgets/construction/create-widget';
import type { WidgetTabViewModel } from 'src/store/types';
import {
    createContainerLayoutViewModel,
    createContainerViewModel,
    mapWidgetTabToViewModel,
} from 'src/store/types/workspaceViewModels';
import { v4 as uuid } from 'uuid';

export function* addWidgetToDashboardSaga(action: AddWidgetToDashboardAction) {
    const currentlyMaximizedContainer: string | undefined = yield select(
        (store: RootState) => store.container.maximizedContainerId,
    );

    if (currentlyMaximizedContainer) {
        yield put(addNewWidgetToContainer(action.widget, currentlyMaximizedContainer));
    } else {
        const { widget } = action;

        const initialTab = yield* createWidgetTabFromCreationInfo(widget);

        const defaultLayout: Layout =
            widget.type === 'alpha-lens'
                ? {
                      h: 0.8,
                      w: 0.33,
                      x: 0.33,
                      y: 0.1,
                  }
                : {
                      x: 0.2,
                      y: 0.4,
                      w: 0.6,
                      h: 0.2,
                  };

        const newContainer: WidgetContainer = {
            id: uuid(),
            tabs: [initialTab],
            name: initialTab.name ?? null,
            layout: defaultLayout,
            activeTabId: initialTab.id,
        };

        const layoutViewModel = createContainerLayoutViewModel(newContainer.layout, newContainer.id);

        const containerViewModel = createContainerViewModel(newContainer);
        const initialTabViewModel: WidgetTabViewModel = yield call(
            mapWidgetTabToViewModel,
            newContainer.id,
            newContainer.tabs[0].id,
            newContainer.tabs[0],
        );

        yield put(addContainer(containerViewModel, initialTabViewModel, layoutViewModel));
    }
}

export function* createWidgetTabSaga(action: AddNewWidgetToContainerAction) {
    const { tab, containerId } = action;

    const asWidgetTab: WidgetTab = yield* createWidgetTabFromCreationInfo(tab);

    const tabViewModel: WidgetTabViewModel = yield call(
        mapWidgetTabToViewModel,
        containerId,
        asWidgetTab.id,
        asWidgetTab,
    );

    yield put(addTab(tabViewModel, containerId));
}

export function* widgetConstructionSagas() {
    yield all([
        takeLatest('addWidgetToDashboard', addWidgetToDashboardSaga),
        takeLatest('addNewWidgetToContainer', createWidgetTabSaga),
    ]);
}
