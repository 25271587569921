import { Instant } from '@js-joda/core';
import type { LocateOrder, NewLocateOrder } from '@thinkalpha/common/contracts/locates/orders.js';
import { LocateOrderStatus } from '@thinkalpha/common/contracts/locates/orders.js';
import type { LocateReceipt } from '@thinkalpha/common/contracts/locates/platforms.js';
import { v4 as uuidv4 } from 'uuid';

type OptimisticOrders = {
    newLocateOrder: NewLocateOrder;
    optimisticOrder: LocateOrder;
};

export const generateOptimisticOrders = (order: Omit<NewLocateOrder, 'optimisticOrderId'>): OptimisticOrders => {
    const optimisticOrderId = uuidv4();

    const newLocateOrder: NewLocateOrder = {
        ...order,
        optimisticOrderId,
    };

    const orderId = uuidv4();
    const optimisticOrder: LocateOrder = {
        ...newLocateOrder,
        orderId,
        id: orderId,
        createdTime: Instant.now(),
        lastUpdateTime: Instant.now(),
        orderStatus: LocateOrderStatus.sent,
        requestedProviderId: order.providerId,
    };

    return {
        newLocateOrder,
        optimisticOrder,
    };
};

type OptimisticReceipt = {
    newLocateReceipts: LocateReceipt[];
    optimisticOrders: LocateOrder[];
};

export const generateOptimisticReceipts = (receipts: Omit<LocateReceipt, 'optimisticOrderId'>[]): OptimisticReceipt => {
    const optimisticOrders: LocateOrder[] = [];
    const newLocateReceipts: LocateReceipt[] = [];

    receipts.forEach((receipt) => {
        const optimisticOrderId = uuidv4();

        const optimisticReceipt: LocateReceipt = {
            ...receipt,
            optimisticOrderId: optimisticOrderId,
        };

        newLocateReceipts.push(optimisticReceipt);

        const orderId = uuidv4();
        const optimisticOrder: LocateOrder = {
            orderId,
            id: orderId,
            optimisticOrderId,
            createdTime: Instant.now(),
            lastUpdateTime: Instant.now(),
            orderStatus: LocateOrderStatus.sent,
            requestedProviderId: receipt.providerId,
            symbol: receipt.symbol,
            quantity: receipt.quantity,
        };

        optimisticOrders.push(optimisticOrder);
    });

    return {
        newLocateReceipts,
        optimisticOrders,
    };
};
