import type { EventChannel, Task } from 'redux-saga';
import { eventChannel } from 'redux-saga';
import { all, call, cancel, cancelled, fork, put, take, select } from 'redux-saga/effects';
import { api } from 'src/api';
import { SocketEvents } from 'src/contracts/push';
import { container } from 'src/ioc/StaticContainer';
import { pushServiceSocket } from 'src/lib/push';
import { localforage } from 'src/lib/serialization/localForage';
import type { RootState } from 'src/store';
import { becomeActiveApp, loseActive } from 'src/store/actions/app';
import { getAccessTokens, getRootUser } from 'src/store/selectors/auth';
import type { UserDetails } from 'src/store/types';
import { v4 as uuid } from 'uuid';

const APP_ITEM_KEY = 'app-id';
const log = container.get('Logger').getSubLogger({ name: 'session' });

async function retrieveAppId() {
    return (await localforage.getItem<string>(APP_ITEM_KEY)) ?? undefined;
}

type SocketMessage = {
    type: 'active-app-set';
    activeAppId: string;
};

function createSocketChannel(socket: typeof pushServiceSocket) {
    log.info({ message: 'Creating socket channel for session service' });

    // `eventChannel` takes a subscriber function
    // the subscriber function takes an `emit` argument to put messages onto the channel
    return eventChannel((emit: (input: SocketMessage) => void) => {
        const handleActiveAppEvent = (activeAppId: string) => {
            emit({ type: 'active-app-set', activeAppId });
        };

        socket.on(SocketEvents.activeApp, handleActiveAppEvent);

        // the subscriber must return an unsubscribe function
        // this will be invoked when the saga calls `channel.close` method
        const unsubscribe = () => {
            socket.off(SocketEvents.activeApp, handleActiveAppEvent);
        };

        return unsubscribe;
    });
}

function* listenToPush(currentAppId: string) {
    const channel: EventChannel<Record<string, never>> = yield call(createSocketChannel, pushServiceSocket);

    try {
        while (true) {
            const socketAction: SocketMessage = yield take(channel);

            if (socketAction.type === 'active-app-set') {
                const newActiveAppId = socketAction.activeAppId;

                if (newActiveAppId !== currentAppId) {
                    log.info({ message: 'Lost active app to claim from app id', newActiveAppId });

                    yield put(loseActive());
                } else {
                    log.info({ message: 'Server says we are the active app' });
                    yield put(becomeActiveApp());
                }
            }
        }
    } catch (e) {
        if (e instanceof Error) {
            log.error({ message: 'Error from push service socket', error: e });
        } else {
            log.fatal({ message: 'Unknown exception', details: e });
        }
    } finally {
        const isCancelled: boolean = yield cancelled();
        if (isCancelled) {
            log.info({ message: 'Closing channel for session barrier' });

            channel.close();
        }
    }
}

function* claimAppId(appId: string) {
    // Don't have to wait for result, the socket will pick this up when verified
    const currentUser: UserDetails | undefined = yield select(getRootUser);

    const tokens: ReturnType<typeof getAccessTokens> = yield select(getAccessTokens);

    if (!tokens.masqAccessToken) {
        yield call(() =>
            api.post('session/claims', { appId }, { headers: currentUser ? { 'X-Masq': currentUser.id } : {} }),
        );
    }
}

function* watchForClaimApp(appId: string) {
    try {
        while (true) {
            yield take('active-app::request');
            yield call(claimAppId, appId);
        }
    } finally {
        const isCancelled: boolean = yield cancelled();
        if (isCancelled) {
            log.info({ message: 'No longer listening for user to claim app' });
        }
    }
}

function* setupService() {
    while (true) {
        // We only care about our session barrier if we are authenticated
        let accessToken = (yield select((state) => state.auth.accessToken)) as RootState['auth']['accessToken'];
        while (!accessToken) {
            yield take('access-token::set');
            accessToken = (yield select((state) => state.auth.accessToken)) as RootState['auth']['accessToken'];
        }

        const storedAppId: string | undefined = yield call(retrieveAppId);
        const appId = storedAppId ?? uuid();
        if (!storedAppId) {
            yield localforage.setItem(APP_ITEM_KEY, appId);
        }

        log.debug({ message: 'App id token for active app identification:', appId });

        // Listen for the active-app being set by the server, claim the active app id for our own, and listen for
        // becoming the active app id

        // We do want to be listening first, so that when we claim, it's for sure
        const pushListener: Task = yield fork(listenToPush, appId);
        const appClaimListener: Task = yield fork(watchForClaimApp, appId);

        yield call(claimAppId, appId);

        // Wait for user to change, and then repeat this cycle
        const currentUser: ReturnType<typeof getRootUser> = yield select(getRootUser);
        listenForNewUser: while (true) {
            yield take('user-details::set');
            const newCurrentUser: ReturnType<typeof getRootUser> = yield select(getRootUser);
            if (newCurrentUser?.id !== currentUser?.id) {
                log.debug({ message: 'User changed, re-claiming app id' });
                break listenForNewUser;
            }
        }

        yield all([cancel(pushListener), cancel(appClaimListener)]);
    }
}

export function* sessionSagas() {
    yield all([setupService()]);
}
