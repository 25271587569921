import { t } from 'i18next';
import type { Widget, WidgetType } from 'src/contracts/workspace';

export interface WidgetViewModel {
    id: WidgetType;
    name: string;
    roles: string[];
}

export const WIDGETS: WidgetViewModel[] = [
    {
        name: 'Results',
        id: 'strategy',
        roles: ['widgets::results'],
    },
    {
        name: 'Watch List',
        id: 'watchlist',
        roles: ['widgets::watchlist'],
    },
    {
        name: 'LAB',
        id: 'locates',
        roles: ['widgets::locates'],
    },
    {
        name: 'Positions',
        id: 'positions',
        roles: ['widgets::positions'],
    },
    {
        name: 'Messages',
        id: 'messages',
        roles: ['widgets::messages'],
    },
    {
        name: 'Accounts',
        id: 'accounts',
        roles: ['widgets::accounts'],
    },
    {
        name: 'Order Blotter',
        id: 'order-blotter',
        roles: ['widgets::order-blotter'],
    },
    {
        name: 'Order Entry',
        id: 'order-entry',
        roles: ['widgets::order-entry'],
    },
    {
        name: 'Trade',
        id: 'simple-order-entry',
        roles: ['widgets::simple-order-entry'],
    },
    {
        name: 'Orders',
        id: 'orders',
        roles: ['widgets::orders'],
    },
    {
        name: 'Table',
        id: 'table',
        roles: ['widgets::table'],
    },
    {
        name: 'Chart',
        id: 'time-series',
        roles: ['widgets::time-series'],
    },
    {
        name: 'Executions',
        id: 'executions',
        roles: ['widgets::executions'],
    },
    {
        name: 'SEARCHalpha',
        id: 'if-then-builder',
        roles: ['widgets::if-then-builder'],
    },
    {
        name: 'SEARCHalpha 2.0',
        id: 'search-alpha',
        roles: ['widgets::search-alpha'],
    },
    {
        name: 'AlphaLens',
        id: 'alpha-lens',
        roles: ['widgets::alpha-lens'],
    },
    {
        name: 'Aggregated Positions',
        id: 'aggregated-positions',
        roles: ['widgets::aggregated-positions'],
    },
    {
        name: 'News',
        id: 'news-and-events',
        roles: ['widgets::news'],
    },
];
for (const widget of WIDGETS) {
    const key = 'widgets::' + widget.id;
    widget.name = t(key, widget.name);
}
Object.freeze(WIDGETS);
const widgetsById = WIDGETS.reduce(
    (acc, widget) => {
        acc[widget.id] = widget;
        return acc;
    },
    {} as Record<WidgetType, WidgetViewModel>,
);
Object.freeze(widgetsById);

export function getWidgetDisplayName(widget: Widget): string {
    const { type } = widget;
    return widgetsById[type].name;
}
