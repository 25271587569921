import type { RootState } from '..';
import type { SetSuperuserAccessEnabledUntilAction } from '../actions/admin';
import type { CloseWorkspaceSaveModalAction } from '../actions/overlay';
import { workspaceSavingOver, openModal, closeActiveModal } from '../actions/overlay';
import { setSnackbar } from '../actions/ui';
import { initializeUniverses } from '../actions/universe';
import type {
    SaveCurrentWorkspaceAction,
    UpdateWorkspaceSettings,
    SubmitNameForSavingAction,
} from '../actions/workspace';
import {
    initializeNormalizedData,
    savingCompleted,
    workspaceSavingFailed,
    setSavingTrue,
    completeWorkspaceResearch,
    saveWorkspace,
    setResearchingWorkspace,
    makeWorkspacePrimary,
} from '../actions/workspace';
import { getContainerViewModels } from '../selectors/container';
import { getContainerLayoutViewModels } from '../selectors/layout';
import { getWidgetTabViewModels } from '../selectors/tab';
import { getAssertedCurrentWorkspace, getCurrentWorkspace } from '../selectors/workspace';
import type {
    ContainerViewModel,
    ContainerLayoutViewModel,
    WidgetTabViewModel,
    WorkspaceViewModel,
    SagaContext,
} from '../types';
import { createWorkspaceFromViewModels } from '../types/workspaceViewModels';
import { waitForAuth } from './auth/helpers';
import { fetchWatchListUniverses } from './universe';
import { t } from 'i18next';
import isEqual from 'lodash/isEqual';
import { push } from 'redux-first-history';
import { all, call, getContext, put, race, select, take, takeLatest } from 'redux-saga/effects';
import { firstValueFrom } from 'rxjs';
import { comparePermissions } from 'src/admin/accounts/permissions-helpers';
import type { ConcreteUniverse } from 'src/contracts/universe';
import type { NewWorkspace, Workspace } from 'src/contracts/workspace';
import { container } from 'src/ioc/StaticContainer';
import { importCacheManager } from 'src/lib/ImportCacheManager';
import { setDefaultWorkspaceId as changeDefaultWorkspaceOnAPI } from 'src/lib/user-preference';
import {
    createWorkspace,
    deleteWorkspace,
    getWorkspaceViewModelSetup,
    saveWorkspace as saveWorkspaceOnApi,
} from 'src/lib/workspaces';
import { createRemoteSliceAction } from 'src/store/lib/createRemoteSlice';
import { confirmLeavingWorkspace } from 'src/store/overlays/confirmLeavingWorkspace';

const log = container.get('Logger').getSubLogger({ name: 'workspace-sagas' });

export function* syncCurrentWorkspace() {
    yield* waitForAuth();

    const pathname: string | undefined = yield select((state) => state.router.location?.pathname);

    if (!pathname?.startsWith('/dashboard')) {
        return;
    }

    log.debug({ message: 'Checking for workspace sync' });

    const workspaceIdFromUrl = pathname.split('/')[2];

    const workspaceId = workspaceIdFromUrl || 'default';

    const { accessToken, masqAccessToken }: RootState['auth'] = yield select((state) => state.auth);
    const container: NonNullable<SagaContext['container']> = yield getContext('container');
    const queryClient = container.get('QueryClient');
    const userDetails: RootState['auth']['user'] = yield select((state: RootState) => state.auth.user);

    yield put(setResearchingWorkspace());

    try {
        const {
            containerViewModels,
            layoutViewModels,
            widgetTabViewModels,
            workspaceViewModel,
            importRefs,
        }: Awaited<ReturnType<typeof getWorkspaceViewModelSetup>> = yield queryClient.fetchUserQuery({
            queryFn: () => getWorkspaceViewModelSetup(workspaceId),
            queryKey: ['workspace', 'active', workspaceId, accessToken, masqAccessToken, userDetails],
        });

        // Kick it off to start seeding the cache, but we don't need to wait for it yet
        // that will be handled by expanding the strategy
        importCacheManager.getImports(importRefs);

        const currentWorkspaceVm: ReturnType<typeof getCurrentWorkspace> = yield select(getCurrentWorkspace);

        if (currentWorkspaceVm === workspaceViewModel) return;

        const wholeStore: RootState = yield select();
        const existingContainers = wholeStore.container.byId;
        const existingLayouts = wholeStore.layout.byContainerId;
        const existingTabs = wholeStore.tab.byId;

        const newOrUpdatedContainers = containerViewModels.filter(
            (container) => !isEqual(existingContainers[container.id], container),
        );

        const newOrUpdatedLayouts = layoutViewModels.filter(
            (layout) => !isEqual(existingLayouts[layout.containerId], layout),
        );

        const serverWatchListUniverses: (ConcreteUniverse | undefined)[] = yield call(
            fetchWatchListUniverses,
            widgetTabViewModels,
            queryClient,
        );

        yield put(initializeUniverses(serverWatchListUniverses.filter((x) => x !== undefined) as ConcreteUniverse[]));

        const newOrUpdatedTabls = widgetTabViewModels.filter((tab) => !isEqual(existingTabs[tab.id], tab));

        const newWorkspaceVm = isEqual(workspaceViewModel, currentWorkspaceVm)
            ? currentWorkspaceVm!
            : workspaceViewModel;

        yield put(
            initializeNormalizedData(newWorkspaceVm, newOrUpdatedContainers, newOrUpdatedLayouts, newOrUpdatedTabls),
        );
    } catch (e) {
        // ToDo: set to an error state
    }

    yield put(completeWorkspaceResearch());
}

export function* onRemoveWorkspace() {
    try {
        const currentWorkspace: WorkspaceViewModel = yield select(getAssertedCurrentWorkspace);
        const currentlyVisibleWorkspaceId = currentWorkspace.id;

        yield call(firstValueFrom, deleteWorkspace(currentlyVisibleWorkspaceId));
        const container: NonNullable<SagaContext['container']> = yield getContext('container');
        const queryClient = container.get('QueryClient');
        queryClient.invalidateQueries({ queryKey: ['workspace'] });

        yield all([
            put(push('/dashboard')),
            put(
                setSnackbar(t('views::dashboard::workspace::remove-success', 'Successfully deleted workspace'), {
                    variant: 'success',
                }),
            ),
        ]);
    } catch (e) {
        yield put(
            setSnackbar(t('views::dashboard::workspace::remove-error', 'There was an error deleting workspace'), {
                variant: 'error',
            }),
        );

        if (e instanceof Error) {
            log.error({ message: 'Deleting workspace failed', error: e });
        } else {
            log.fatal({ message: 'unknown exception', details: e });
        }
    }
}

export function* onSaveWorkspace(action: SaveCurrentWorkspaceAction) {
    try {
        yield put(setSavingTrue());

        const currentWorkspace: WorkspaceViewModel = yield select(getAssertedCurrentWorkspace);

        let name = currentWorkspace.name;

        if (action.saveType === 'saveAs') {
            const [modalResult]: [
                { savingSubmitted: SubmitNameForSavingAction } | { modalClosed: CloseWorkspaceSaveModalAction },
            ] = yield all([
                race({
                    savingSubmitted: take('submitNameForSaving'),
                    modalClosed: take(['close::workspace-save::modal', 'cancel-close::workspace-save::modal']),
                }),
                put(openModal({ type: 'get-new-workspace-name', title: 'Save copy as new workspace' })),
            ]);

            if ('modalClosed' in modalResult) {
                yield all([
                    put(
                        setSnackbar(t('views::dashboard::workspace::save-canceled', 'Canceled Workspace Saving'), {
                            variant: 'error',
                        }),
                    ),
                    put(closeActiveModal('name-retrieved')),
                    put(savingCompleted()),
                ]);
                return;
            }

            yield put(closeActiveModal('name-retrieved'));

            name = modalResult.savingSubmitted.name;
        }

        const container: NonNullable<SagaContext['container']> = yield getContext('container');
        const queryClient = container.get('QueryClient');

        const containersById: Record<string, ContainerViewModel> = yield select(getContainerViewModels);
        const tabsById: Record<string, WidgetTabViewModel> = yield select(getWidgetTabViewModels);
        const normalizedLayoutsByKey: Record<string, ContainerLayoutViewModel> =
            yield select(getContainerLayoutViewModels);

        const { containerIds } = currentWorkspace;
        const normalizedContainers = containerIds.map((id) => containersById[id]);
        const tabIds = normalizedContainers.flatMap((container) => container.tabIds);
        const normalizedTabs = tabIds.map((id) => tabsById[id]);
        const normalizedLayouts = containerIds.map((id) => normalizedLayoutsByKey[id]);

        const unNormalizedWorkspace = createWorkspaceFromViewModels(
            currentWorkspace,
            normalizedContainers,
            normalizedTabs,
            normalizedLayouts,
        );

        const isDefaultWorkspace = currentWorkspace.isDefaultWorkspace;
        const mustMakeNew = comparePermissions(currentWorkspace.permissions.effectiveAccess, 'writer') < 0;
        let newId: string = '';

        if (mustMakeNew || action.saveType === 'saveAs') {
            const asNewWorkspace: NewWorkspace = {
                ...unNormalizedWorkspace,
                name,
                originatedFrom: currentWorkspace.id,
            };
            const fullyNewWorkspace: Workspace = yield call(firstValueFrom, createWorkspace(asNewWorkspace));

            if (isDefaultWorkspace) {
                yield changeDefaultWorkspaceOnAPI(fullyNewWorkspace.id);
            } else {
                newId = fullyNewWorkspace.id;
            }
        } else {
            yield call(firstValueFrom, saveWorkspaceOnApi(unNormalizedWorkspace, true));
            //yield put(prepareWorkspaceActionCreator(savedWorkspace.id));
        }

        yield queryClient.invalidateQueries({ queryKey: ['workspace'] });

        if (newId) {
            yield put(push(`/dashboard/${newId}`));
        }

        yield all([
            put(
                setSnackbar(
                    action.saveType === 'save'
                        ? t('views::dashboard::workspace::save-success', 'Workspace saved')
                        : t('views::dashboard::workspace::create-success', 'Workspace created'),
                    {
                        variant: 'success',
                    },
                ),
            ),
            put(savingCompleted()),
        ]);
    } catch (e) {
        yield all([
            put(
                setSnackbar(t('views::dashboard::workspace::save-error', 'Could not save workspace'), {
                    variant: 'error',
                }),
            ),
            put(workspaceSavingFailed()),
        ]);

        if (e instanceof Error) {
            log.error({ message: `Saving workspace failed`, error: e });
        } else {
            log.fatal({ message: 'unknown exception', details: e });
        }
    }
}

export function* onCreateBlankWorkspace() {
    const [modalResult]: [
        { savingSubmitted: SubmitNameForSavingAction } | { modalClosed: CloseWorkspaceSaveModalAction },
    ] = yield all([
        race({
            savingSubmitted: take('submitNameForSaving'),
            modalClosed: take(['close::workspace-save::modal', 'cancel-close::workspace-save::modal']),
        }),
        put(openModal({ type: 'get-new-workspace-name', title: 'Provide a name for the new workspace' })),
    ]);

    if ('modalClosed' in modalResult) {
        yield all([
            put(
                setSnackbar(t('views::dashboard::workspace::create-canceled', 'Canceled Create Workspace'), {
                    variant: 'error',
                }),
            ),
            put(closeActiveModal('name-retrieved')),
            put(savingCompleted()),
        ]);
        return;
    }

    yield put(closeActiveModal('name-retrieved'));

    const name = modalResult.savingSubmitted.name;

    const newWorkspace: NewWorkspace = {
        containers: [],
        layoutSettings: {
            containerLayoutLocked: false,
            gridSnappingEnabled: true,
        },
        description: '',
        name,
    };
    const fullyNewWorkspace: Workspace = yield call(firstValueFrom, createWorkspace(newWorkspace));

    const newWorkspaceId = fullyNewWorkspace.id;

    const container: NonNullable<SagaContext['container']> = yield getContext('container');
    const queryClient = container.get('QueryClient');
    yield queryClient.invalidateQueries({ queryKey: ['workspace'] });

    yield put(push(`/dashboard/${newWorkspaceId}`));

    yield all([
        put(
            setSnackbar(t('views::dashboard::workspace::create-success', 'Workspace created'), {
                variant: 'success',
            }),
        ),
    ]);
}

export function* saveThenCloseWorkspace() {
    const workspacePrimary: boolean = yield select(
        (state: RootState) => !!state.remoteSlice.confirmLeavingWorkspace.workspacePrimary,
    );

    if (workspacePrimary) {
        const currentWorkspace: WorkspaceViewModel = yield select(getAssertedCurrentWorkspace);
        yield put(makeWorkspacePrimary(currentWorkspace.id));
    }

    // We want to put the next action rather than call the generator function
    // so that if we have another intent-blocking event (such as check for template saving)
    // we will block accordingly
    yield all([put(saveWorkspace('save')), take(['saveWorkspaceEvent', 'workspaceSavingFailed'])]);
    yield put(createRemoteSliceAction(confirmLeavingWorkspace, 'closeModal', 'saved'));
}

export function* onSaveWorkspaceAsPrimary(action: ReturnType<typeof makeWorkspacePrimary>) {
    yield changeDefaultWorkspaceOnAPI(action.id);
}

export function* saveThenCloseTemplateWorkspace() {
    // This time we want to call the generator function
    // since saving for a template is the final status
    yield* onSaveWorkspace({ saveType: 'save', type: 'saveWorkspaceEvent' });
    yield put(workspaceSavingOver());
}

export function* onupdateWorkspaceSettings(action: UpdateWorkspaceSettings) {
    const currentWorkspace: WorkspaceViewModel = yield select(getAssertedCurrentWorkspace);
    const isCurrentlyDefaultOnServer = currentWorkspace.isDefaultWorkspace;

    if (action.settings.isPrimaryWorkspace && !isCurrentlyDefaultOnServer) {
        try {
            yield changeDefaultWorkspaceOnAPI(currentWorkspace.id);
            // ToDo: Refactor this
        } catch (e) {
            // ToDo: Optimistic rollback
        }
    }

    yield put(saveWorkspace('save'));
}

function* openWorkspaceTemplateLibrary() {
    // We do this as this action is intent-based and may be blocked depending
    // on the current state of the workspace
    yield put(openModal({ type: 'workspace-library' }));
}

function* checkIfWorkspaceNeedsToSync(action: SetSuperuserAccessEnabledUntilAction) {
    const pathname: string | undefined = yield select((state) => state.router.location?.pathname);
    if (!pathname?.startsWith('/dashboard')) {
        return;
    }

    if (action.superuserAccessEnabledUntil) {
        const currentWorkspace: WorkspaceViewModel | null = yield select(getCurrentWorkspace);
        if (!currentWorkspace) {
            // Did not have access before
            yield* syncCurrentWorkspace();
        }
    }
}

export function* workspaceSagas() {
    yield all([
        takeLatest('updateWorkspaceSettings', onupdateWorkspaceSettings),
        takeLatest('removeWorkspaceEvent', onRemoveWorkspace),
        takeLatest('saveWorkspaceEvent', onSaveWorkspace),
        takeLatest('createBlankWorkspaceEvent', onCreateBlankWorkspace),
        takeLatest(confirmLeavingWorkspace.actionTypes.saveThenCloseModal, saveThenCloseWorkspace),
        takeLatest('saveWorkspaceAsPrimary', onSaveWorkspaceAsPrimary),
        takeLatest('allow-template-workspace-to-save', saveThenCloseTemplateWorkspace),
        takeLatest('workspace-template-library::open', openWorkspaceTemplateLibrary),
        takeLatest('superuser::set-expiration', checkIfWorkspaceNeedsToSync),
        takeLatest(
            [
                '@@router/CALL_HISTORY_METHOD',
                'access-token::set',
                'access-token::clear',
                'masq::access-token::set',
                'bootstrap::complete',
            ],
            syncCurrentWorkspace,
        ),
    ]);
}
