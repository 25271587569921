import { PermissionType } from '@thinkalpha/common/contracts/permissions.js';
import type {
    AllowPermission,
    DenyPermission,
    PermissionDirective,
    PermissionSubject,
} from '@thinkalpha/common/contracts/permissions.js';
import { UnreachableCaseError } from 'ts-essentials';

export function getLeastPrivilegedPermission(...permissions: PermissionType[]): PermissionType;
export function getLeastPrivilegedPermission(...permissions: (PermissionType | null)[]): PermissionType | undefined;
export function getLeastPrivilegedPermission(
    ...permissions: (PermissionType | undefined)[]
): PermissionType | undefined;
export function getLeastPrivilegedPermission(
    ...permissions: (PermissionType | null | undefined)[]
): PermissionType | undefined {
    return PermissionType[
        permissions
            .values()
            .map((x) => (x === null || x === undefined ? -1 : PermissionType.indexOf(x)))
            .min()
    ];
}

export function getMostPrivilegedPermission(...permissions: PermissionType[]): PermissionType;
export function getMostPrivilegedPermission(...permissions: (PermissionType | null)[]): PermissionType | undefined;
export function getMostPrivilegedPermission(...permissions: (PermissionType | undefined)[]): PermissionType | undefined;
export function getMostPrivilegedPermission(
    ...permissions: (PermissionType | null | undefined)[]
): PermissionType | undefined {
    return PermissionType[
        permissions
            .values()
            .map((x) => (x === null || x === undefined ? -1 : PermissionType.indexOf(x)))
            .max()
    ];
}

export function comparePermissions(a: PermissionType | null | undefined, b: PermissionType | null | undefined): number {
    const aIdx = a ? PermissionType.indexOf(a) : 0;
    const bIdx = b ? PermissionType.indexOf(b) : 0;
    return aIdx - bIdx;
}
export function doesAccessGrantAllowRequest(grant: PermissionType | undefined, request: PermissionType): boolean {
    if (!grant) return false;
    if (!request) return true;
    return PermissionType.indexOf(grant) >= PermissionType.indexOf(request);
}

export function isAllowPermission(permission: PermissionDirective): permission is AllowPermission {
    return typeof permission === 'string';
}

export function isDenyPermission(permission: PermissionDirective): permission is DenyPermission {
    return typeof permission === 'object' && 'deny' in permission;
}

export function getSubjectForPermission(permission: PermissionDirective): PermissionSubject {
    if (isAllowPermission(permission)) return permission;
    if (isDenyPermission(permission)) return permission.deny;
    throw new UnreachableCaseError(permission);
}
