import type { SocketEmitActions } from '../socket';
import type { OnOrderUpdateAction } from './locates';
import type { LocateOrder } from '@thinkalpha/common/contracts/locates/orders.js';
import type { Socket } from 'socket.io-client';

export interface BeginLocatesSetupProcessAction {
    type: 'locates::beginLocatesSetupProcess';
}
export const beginLocatesSetupProcess = (): BeginLocatesSetupProcessAction => ({
    type: 'locates::beginLocatesSetupProcess',
});

export interface LocatesSocketConnectedEvent {
    type: 'locates::socket::connected';
}
export const locatesSocketConnectedAction = (): LocatesSocketConnectedEvent => ({
    type: 'locates::socket::connected',
});

export interface LocatesSocketConnectingEvent {
    type: 'locates::socket::connecting';
}
export const locatesSocketConnectingAction = (): LocatesSocketConnectingEvent => ({
    type: 'locates::socket::connecting',
});

export interface LocatesSocketDisconnectedEvent {
    type: 'locates::socket::disconnected';
}
export const locatesSocketDisconnectedAction = (): LocatesSocketDisconnectedEvent => ({
    type: 'locates::socket::disconnected',
});

export interface LocatesSocketSetupCompletedEvent {
    type: 'locates::socket::setup::completed';
}
export const locatesSocketSetupCompletedAction = (): LocatesSocketSetupCompletedEvent => ({
    type: 'locates::socket::setup::completed',
});

export interface LocatesEmissionAction {
    type: 'locates::socket::emit';
    payload: SocketEmitActions<Socket>;
}
export const makeLocatesEmissionAction = (payload: SocketEmitActions<Socket>): LocatesEmissionAction => ({
    type: 'locates::socket::emit',
    payload,
});

export const onOrderUpdateActionCreator = (payload: LocateOrder): OnOrderUpdateAction => ({
    type: 'locates::onOrderUpdate',
    orders: [payload],
    source: 'socket',
});

export type LocatesSocketAction =
    | BeginLocatesSetupProcessAction
    | LocatesSocketConnectedEvent
    | LocatesSocketConnectingEvent
    | LocatesSocketDisconnectedEvent
    | LocatesSocketSetupCompletedEvent
    | LocatesEmissionAction
    | OnOrderUpdateAction;
