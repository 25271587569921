export type BeginBootstrapAction = { type: 'bootstrap::begin' };
export const beginBootstrap = (): BeginBootstrapAction => ({ type: 'bootstrap::begin' });

export type CompleteBootstrapAction = { type: 'bootstrap::complete' };
export const completeBootstrap = (): CompleteBootstrapAction => ({ type: 'bootstrap::complete' });

export type RequestActiveAppAction = { type: 'active-app::request' };
export const requestActiveApp = (): RequestActiveAppAction => ({ type: 'active-app::request' });

export type BecomeActiveApp = { type: 'active-app::claim' };
export const becomeActiveApp = (): BecomeActiveApp => ({ type: 'active-app::claim' });

export type LoseActiveAppAction = { type: 'active-app::unclaim' };
export const loseActive = (): LoseActiveAppAction => ({ type: 'active-app::unclaim' });

export type SetCurrentAppVersionAction = { type: 'app-version::set'; version: string };
export const setCurrentAppVersion = (version: string): SetCurrentAppVersionAction => ({
    type: 'app-version::set',
    version,
});

export type CheckAppVersionAction = { type: 'app-version::check' };
export const checkAppVersion = (): CheckAppVersionAction => ({ type: 'app-version::check' });

export type AppBecameStaleAction = { type: 'app-version::stale' };
export const appBecameStale = (): AppBecameStaleAction => ({ type: 'app-version::stale' });

export type StaleAppAcknowledgedAction = { type: 'app-version::stale-acknowledged' };
export const acknowledgedStaleApp = (): StaleAppAcknowledgedAction => ({ type: 'app-version::stale-acknowledged' });

export type AppStateAction =
    | SetCurrentAppVersionAction
    | AppBecameStaleAction
    | BecomeActiveApp
    | BeginBootstrapAction
    | CheckAppVersionAction
    | CompleteBootstrapAction
    | LoseActiveAppAction
    | RequestActiveAppAction
    | StaleAppAcknowledgedAction;
