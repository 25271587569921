import {
    type ContainerViewModel,
    type ContainerLayoutViewModel,
    type WidgetTabViewModel,
    type WorkspaceViewModel,
    type WidgetViewModel,
    type SearchAlphaWidgetViewModel,
} from '.';
import type { IndicatorImportViewModel } from 'src/contracts/dictionary-view-model';
import type { Strategy } from 'src/contracts/strategy';
import type { Layout, WidgetContainer, WidgetTab, Workspace } from 'src/contracts/workspace';
import { importCacheManager } from 'src/lib/ImportCacheManager';
import { initialInputState, mapOrderEntryInputsToOrderBase } from 'src/routes/widgets/OrderEntryWidget/state';

export const createWorkspaceViewModel = (workspace: Workspace, isDefaultWorkspace = false): WorkspaceViewModel => {
    return {
        permissions: workspace.permissions,
        id: workspace.id,
        isTemplate: workspace.isTemplate,
        name: workspace.name,
        layoutSettings: workspace.layoutSettings,
        description: workspace.description,
        isDirty: false,
        activeContainerId: workspace.containers[0]?.id,
        containerIds: workspace.containers.map((container) => container.id),
        isDefaultWorkspace: isDefaultWorkspace,
        maximizedContainerId: workspace.maximizedContainerId,
    };
};

export const createContainerLayoutViewModel = (layout: Layout, containerId: string): ContainerLayoutViewModel => {
    return {
        ...layout,
        containerId: containerId,
    };
};

export const createContainerViewModel = (container: WidgetContainer): ContainerViewModel => {
    return {
        id: container.id,
        name: container.name,
        activeTabId: container.activeTabId ?? container.tabs[0].id,
        tabIds: container.tabs.map((tab) => tab.id),
    };
};

export const createTabViewModel = <T extends WidgetViewModel = WidgetViewModel>(
    tab: WidgetTab,
    widget: T,
    isActive: boolean,
    containerId: string,
): WidgetTabViewModel => {
    return {
        id: tab.id,
        name: tab.name,
        widget,
        isActive,
        containerId: containerId,
        channelId: tab.widget.channelId || null,
    };
};

export const createWorkspaceFromViewModels = (
    workspace: WorkspaceViewModel,
    containers: ContainerViewModel[],
    widgetTabs: WidgetTabViewModel[],
    layouts: ContainerLayoutViewModel[],
): Workspace => {
    return {
        id: workspace.id,
        name: workspace.name,
        permissions: workspace.permissions,
        isTemplate: workspace.isTemplate,
        description: workspace.description,
        maximizedContainerId: workspace.maximizedContainerId,
        containers: containers.map((container) =>
            createContainerFromViewModel(
                container,
                widgetTabs.filter((tab) => tab.containerId === container.id),
                layouts.find((x) => x.containerId === container.id)!,
            ),
        ),
        layoutSettings: workspace.layoutSettings,
    };
};

export const createContainerFromViewModel = (
    normalizedContainer: ContainerViewModel,
    normalizedWidgetTabs: WidgetTabViewModel[],
    normalizedLayout: ContainerLayoutViewModel,
): WidgetContainer => {
    return {
        id: normalizedContainer.id,
        name: normalizedContainer.name,
        activeTabId: normalizedContainer.activeTabId,
        layout: createLayoutFromViewModel(normalizedLayout),
        tabs: normalizedWidgetTabs.map((tab) => createTabFromViewModel(tab)),
    };
};

export const createLayoutFromViewModel = (layout: ContainerLayoutViewModel): Layout => {
    return {
        ...layout,
        ...(layout.screen ? { screen: layout.screen } : {}),
    };
};

export const createTabFromViewModel = (tab: WidgetTabViewModel): WidgetTab => {
    /**
     * Ugly hack time, we need to migrate simple OE and OE widgets to no longer use formData or displayMode
     *
     * Saving formData to the workspace was a mistake, and I am working on a path to remove it
     * But we want to make sure the UI and server code can move independently
     *
     * Both displayMode, and formData are no longer needed by the UI from a workspace perspective
     * The hack here injects back default data for a formData save, it does nothing but
     * allow a workspace to be saved when OE or Trade widgets are present
     *
     */
    if (tab.widget.type === 'simple-order-entry') {
        tab.widget.formData = mapOrderEntryInputsToOrderBase(initialInputState, '');
        tab.widget.displayMode = 'simple';
    }

    if (tab.widget.type === 'order-entry') {
        tab.widget.formData = mapOrderEntryInputsToOrderBase(initialInputState, '');
        tab.widget.displayMode = 'legacy';
    }
    /** end ugly hack */

    return {
        id: tab.id,
        name: tab.name,
        widget: tab.widget,
    };
};

export async function mapWidgetTabToViewModel(
    containerId: string,
    activeTabId: string,
    tab: WidgetTab,
): Promise<WidgetTabViewModel> {
    const { widget } = tab;

    switch (widget.type) {
        case 'if-then-builder': {
            const { strategy } = widget;

            if (strategy && typeof strategy !== 'string') {
                const fullStrategy = await expandImportsOnStrategy(strategy);

                return createTabViewModel(
                    tab,
                    { ...widget, strategy: fullStrategy },
                    activeTabId === tab.id,
                    containerId,
                );
            }

            return createTabViewModel(tab, { ...widget, strategy }, activeTabId === tab.id, containerId);
        }
        case 'strategy':
        case 'watchlist': {
            const { strategies } = widget;

            const sanitizedStrategies = await Promise.all(
                (strategies ?? []).map(async (strategy) => {
                    if (typeof strategy === 'string') return strategy;
                    const fullStrategy = await expandImportsOnStrategy(strategy);
                    return fullStrategy;
                }),
            );

            return createTabViewModel(
                tab,
                { ...widget, strategies: sanitizedStrategies },
                activeTabId === tab.id,
                containerId,
            );
        }

        case 'orders':
        case 'executions':
        case 'messages':
        case 'positions': {
            return createTabViewModel(tab, { ...widget, symbolFilter: '' }, activeTabId === tab.id, containerId);
        }
        case 'search-alpha': {
            return createTabViewModel<SearchAlphaWidgetViewModel>(
                tab,
                {
                    channelId: null,
                    columnTemplate: null,
                    displayingSearchForm: true,
                    displayingResults: true,
                    filter: '',
                    scrolling: false,
                    splitPercentage: 50,
                    strategy: null,
                    type: 'search-alpha',
                    universe: null,
                    ...(widget as Partial<SearchAlphaWidgetViewModel>),
                },
                activeTabId === tab.id,
                containerId,
            );
        }

        default:
            return createTabViewModel(tab, widget, activeTabId === tab.id, containerId);
    }
}

export async function generateWidgetTabViewModels(workspace: Workspace): Promise<WidgetTabViewModel[]> {
    return Promise.all(
        workspace.containers.flatMap((container) =>
            container.tabs.map<Promise<WidgetTabViewModel>>((tab) =>
                mapWidgetTabToViewModel(container.id, container.activeTabId ?? container.tabs[0].id, tab),
            ),
        ),
    );
}

export async function expandImportsOnStrategy(strategy: Strategy): Promise<Strategy<IndicatorImportViewModel>> {
    const fullImports = await importCacheManager.getImports(strategy.plan.imports);

    return {
        ...strategy,
        plan: {
            ...strategy.plan,
            imports: fullImports,
        },
    };
}
