import type { FormData } from './configurable-form';
import type { Instant } from '@js-joda/core';
import { ZoneId } from '@js-joda/core';
import { InstrumentType } from 'src/contracts/market';
import type { OptionalOrderInformation, OrderBase } from 'src/contracts/order';
import { Exchange, OrderDuration, OrderType, Route, NewOrderSide } from 'src/contracts/order';
import { container } from 'src/ioc/StaticContainer';

const log = container.get('Logger').getSubLogger({ name: 'order-entry-state' });

export type OrderEntryAction = NewOrderSide | 'cancel';
export type OrderCancelSide = NewOrderSide | 'all';

export const isOrderCancelSide = (action: string): action is OrderCancelSide => {
    return (
        action === NewOrderSide.BUY ||
        action === NewOrderSide.SELL ||
        action === NewOrderSide.CLOSEOUT ||
        action === 'all'
    );
};

export interface Company {
    ticker: string;
    companyName: string;
}

export type OrderEntryInputs = {
    view: OrderEntryAction;
    quantity: number;
    exchange?: string;
    route?: string;
    orderType: string;
    limitPrice: string;
    duration: string;
    cancelSide: OrderCancelSide;
    secondaryForm: FormData;
};

export const initialInputState: OrderEntryInputs = {
    view: NewOrderSide.BUY,
    cancelSide: 'all',
    quantity: 100,
    exchange: '',
    route: '',
    orderType: 'market',
    limitPrice: '100',
    duration: 'day',
    secondaryForm: {},
};

type SetViewAction = {
    type: 'setView';
    view: OrderEntryAction;
};

type SetQuantityAction = {
    type: 'setQuantity';
    quantity: number;
};

type UpdateExchange = {
    type: 'updateExchange';
    exchange: string | undefined;
    route: string | undefined;
};

type SetRouteAction = {
    type: 'setRoute';
    route: string;
};

type SetOrderTypeAction = {
    type: 'setOrderType';
    orderType: string;
};

type SetLimitPriceAction = {
    type: 'setLimitPrice';
    limitPrice: string;
};

type SetLimitPriceAndSwitchTypeAction = {
    type: 'setLimitPriceAndSwitchType';
    limitPrice: string;
    orderType?: OrderType;
};

type SetDurationAction = {
    type: 'setDuration';
    duration: string;
};

type SetCancelSideAction = {
    type: 'setCancelSide';
    cancelSide: OrderCancelSide;
};

type SetSecondaryFormAction = {
    type: 'setSecondaryForm';
    secondaryForm: FormData;
};

type SetOrderEntryInputsAction = {
    type: 'setOrderEntryInputs';
    state: OrderEntryInputs;
};

export type OrderEntryInputAction =
    | SetViewAction
    | SetQuantityAction
    | UpdateExchange
    | SetRouteAction
    | SetOrderTypeAction
    | SetLimitPriceAction
    | SetLimitPriceAndSwitchTypeAction
    | SetDurationAction
    | SetCancelSideAction
    | SetSecondaryFormAction
    | SetOrderEntryInputsAction;

export const orderEntryInputReducer = (state: OrderEntryInputs, action: OrderEntryInputAction): OrderEntryInputs => {
    switch (action.type) {
        case 'setView':
            return { ...state, view: action.view };
        case 'setQuantity':
            return { ...state, quantity: action.quantity };
        case 'updateExchange':
            return {
                ...state,
                exchange: action.exchange,
                route: action.route,
                orderType: state.orderType === '' ? OrderType.MARKET : state.orderType,
            };
        case 'setRoute':
            return { ...state, route: action.route, secondaryForm: {} };
        case 'setOrderType':
            return { ...state, orderType: action.orderType };
        case 'setLimitPrice':
            return { ...state, limitPrice: action.limitPrice };
        case 'setLimitPriceAndSwitchType':
            return { ...state, limitPrice: action.limitPrice, orderType: action.orderType ?? OrderType.LIMIT };
        case 'setDuration':
            return { ...state, duration: action.duration };
        case 'setCancelSide':
            return { ...state, cancelSide: action.cancelSide };
        case 'setSecondaryForm':
            return { ...state, secondaryForm: action.secondaryForm };
        case 'setOrderEntryInputs':
            return { ...action.state };
        default:
            return state;
    }
};

export const cleanupSecondaryFormValues = (form: FormData): FormData => {
    // the user's local timezone in a js-joda recognizable format
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const formCopy = { ...form };

    if (formCopy['startTime']) {
        // convert the entered time from the user's local timezone to UTC
        const UTCTimeStamp = (formCopy['startTime'] as Instant)
            .atZone(ZoneId.of(timezone))
            .withZoneSameInstant(ZoneId.of('Etc/UTC'));

        formCopy['startTime'] = UTCTimeStamp.toInstant();
    }
    if (formCopy['endTime']) {
        // convert the entered time from the user's local timezone to UTC
        const UTCTimeStamp = (formCopy['endTime'] as Instant)
            .atZone(ZoneId.of(timezone))
            .withZoneSameInstant(ZoneId.of('Etc/UTC'));

        formCopy['endTime'] = UTCTimeStamp.toInstant();
    }

    return formCopy;
};

const mapOptionalOrderInformationToFormData = (order: OptionalOrderInformation): FormData => {
    const formData = {} as FormData;

    for (const key of Object.keys(order)) {
        formData[key] = order[key];
    }

    return formData;
};

export const mapOrderBaseToOrderEntryInputs = (orderBase: Omit<OrderBase, 'account'>): OrderEntryInputs => {
    return {
        view: NewOrderSide.BUY, // confirmed with Adam Lewensky, should have to navigate to special orders
        quantity: orderBase.quantity ?? 100,
        exchange: orderBase.exchange ?? '',
        route: orderBase.route ?? '',
        orderType: orderBase.type ?? '',
        limitPrice: String(orderBase.target) ?? '100',
        duration: orderBase.duration ?? '',
        cancelSide: 'all', // we don't save if the user is cancelling anyway, so it can default to 'all'
        secondaryForm: orderBase.attributes ? mapOptionalOrderInformationToFormData(orderBase.attributes) : {},
    };
};

const isExchange = (exchange: string | undefined): exchange is Exchange => {
    if (!exchange) return false;
    const value = Object.values(Exchange).some((e) => e === exchange);
    if (!value) log.info({ message: 'isExchange was given an invalid exchange: ', exchange });
    return value;
};

const isRoute = (route: string | undefined): route is Route => {
    if (!route) return false;
    const value = Object.values(Route).some((r) => r === route);
    if (!value) log.info({ message: 'isRoute was given an invalid exchange: ', route });
    return value;
};

const isOrderType = (orderType: string): orderType is OrderType => {
    const value = Object.values(OrderType).some((o) => o === orderType);
    if (!value) log.info({ message: 'isOrderType was given an invalid exchange: ', orderType });
    return value;
};

const isDuration = (duration: string): duration is OrderDuration => {
    const value = Object.values(OrderDuration).some((d) => d === duration);
    if (!value) log.info({ message: 'isDuration was given an invalid exchange: ', duration });
    return value;
};

export const mapOrderEntryInputsToOrderBase = (
    inputState: OrderEntryInputs,
    symbol: string,
): Omit<OrderBase, 'account'> => {
    const orderBase: Omit<OrderBase, 'account'> = {
        instrument: { symbol: symbol, type: InstrumentType.equity },
        side: NewOrderSide.BUY, // confirmed with Adam Lewensky, should have to navigate to special orders
        quantity: inputState.quantity,
        exchange: isExchange(inputState.exchange) ? inputState.exchange : undefined,
        route: isRoute(inputState.route) ? inputState.route : undefined,
        type: isOrderType(inputState.orderType) ? inputState.orderType : OrderType.MARKET,
        target: Number(inputState.limitPrice),
        duration: isDuration(inputState.duration) ? inputState.duration : OrderDuration.day,
        attributes: inputState.secondaryForm,
    };

    return orderBase;
};
